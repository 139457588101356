import React, { lazy } from "react";
import { ThemeProvider } from "@mui/material/styles";

// project imports
import GuestGuard from "../features/auth/GuestGuard";
import Loadable from "../ui-component/Loadable";
import MinimalLayout from "../features/layout/MinimalLayout";
import theme from "../theme/main";
import store from "../store";

const LoginView = Loadable(lazy(() => import("../views/LoginView")));
const state = store.getState();

const LoginRoutes = {
  path: "/",
  element: (
    <ThemeProvider theme={theme(state.theme)}>
      <GuestGuard>
        <MinimalLayout />
      </GuestGuard>
    </ThemeProvider>
  ),
  children: [
    {
      path: "/login",
      element: <LoginView />,
    },
  ],
};

export default LoginRoutes;
