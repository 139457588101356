import { unwrapResult } from "@reduxjs/toolkit";
import { currentUser, setCurrentUser } from "../features/auth/authSlice";
import { notifyError, notifySuccess } from "../features/notistack/notistackSlice";
import store from "../store";

/**
 * Current User
 */
export const getCurrentUser = async () => {
  const result = await store.dispatch(currentUser());

  try {
    unwrapResult(result);
    const { email, name, role } = result.payload;
    store.dispatch(setCurrentUser({ email, name, role }));
    store.dispatch(notifySuccess(`Welcome, ${name}`));
  } catch (error) {
    store.dispatch(notifyError(`Failed to load user: ${error.meessage}`));
    console.error(error);
  }
};
