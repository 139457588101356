import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from "../../api/client";

const initialState = {
  email: null,
  isLoggedIn: false,
  isInitialized: false,
  name: null,
  role: null,
  status: "idle",
};

/**
 * Current user
 */
export const currentUser = createAsyncThunk("auth/currentUser", async (data) => {
  const response = await client.get(`${process.env.REACT_APP_CDS}/editor/v1/current_user.json`, {});

  return response.data;
});

/**
 * Login to CDS.
 * Note: Function returns response as payload to enable accessing header data.
 */
export const loginCDS = createAsyncThunk("auth/login", async (data) => {
  const response = await client.post(`${process.env.REACT_APP_CDS}/users/sign_in.json`, {
    user: {
      email: data.email,
      password: data.password,
    },
  });

  return response;
});

/**
 * Logout of out CDS.
 */
export const logoutCDS = createAsyncThunk("auth/logout", async (data) => {
  const response = await client.get(`${process.env.REACT_APP_CDS}/users/sign_out.json`, {});

  return response.data;
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCurrentUser(state, action) {
      state.email = action?.payload?.email;
      state.name = action?.payload?.name;
      state.role = action?.payload?.role;
    },
    setLogin(state, action) {
      state.isLoggedIn = true;
      state.isInitialized = true;
    },
    setLogout(state, action) {
      state.email = null;
      state.isLoggedIn = false;
      state.isInitialized = true;
      state.name = null;
      state.role = null;
    },
  },
  extraReducers(builder) {
    builder
      // current user
      .addCase(currentUser.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(currentUser.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(currentUser.rejected, (state, action) => {
        state.status = "failed";
      })
      // login
      .addCase(loginCDS.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(loginCDS.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(loginCDS.rejected, (state, action) => {
        state.status = "failed";
      })
      // logout
      .addCase(logoutCDS.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(logoutCDS.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(logoutCDS.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export const { setCurrentUser, setLogin, setLogout } = authSlice.actions;

export const authIsInitialized = (state) => state.auth.isInitialized;

export const authIsLoggedIn = (state) => state.auth.isLoggedIn;

export default authSlice.reducer;
