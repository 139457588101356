import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from "../../api/client";
import { numberComparer } from "../../utils/sort";

/*
Usage:

  Select all jobs:
      const jobs = useSelector(selectAllJobs)

  Select job:
      const job = useSelector((state) => selectJobById(state, jobId))
 */

const initialState = {
  jobs: [],
  status: "idle",
  statusMessage: null,
};

export const fetchJobs = createAsyncThunk("jobs/fetchJobs", async () => {
  const response = await client.get(`${process.env.REACT_APP_CDS}/editor/v1/jobs.json`);
  return response.data;
});

const jobsSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // Fetch jobs
      .addCase(fetchJobs.pending, (state, action) => {
        state.status = "loading";
        state.statusMessage = "Loading jobs...";
      })
      .addCase(fetchJobs.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.jobs = action.payload;
      })
      .addCase(fetchJobs.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export const {} = jobsSlice.actions;

export default jobsSlice.reducer;

export const selectAllJobs = (state) => state.jobs.jobs;

export const selectAllJobsDescending = (state) => state.jobs.jobs?.slice().sort(numberComparer).reverse();

export const selectJobById = (state, jobId) => state.jobs.jobs?.find((job) => job.id === parseInt(jobId));

export const selectJobByNumber = (state, jobNumber) => state.jobs.jobs?.find((job) => job.number === jobNumber);
