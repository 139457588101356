import React from "react";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline, GlobalStyles, StyledEngineProvider } from "@mui/material";
import { Provider } from "react-redux";
import { RecoilRoot } from "recoil";
import { JWTProvider } from "./contexts/JWTContext";
import Notification from "./components/Notification";
import NotistackProvider from "./providers/NotistackProvider";
import Routes from "./routes";
import globalStyles from "./theme/globalStyles";
import store from "./store";
import "./assets/scss/style.scss";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

function App() {
  return (
    <RecoilRoot>
      <Provider store={store}>
        <StyledEngineProvider injectFirst>
          <CssBaseline />
          <GlobalStyles styles={globalStyles} />
          <NotistackProvider>
            <Notification />
            <BrowserRouter>
              <JWTProvider>
                <Routes />
              </JWTProvider>
            </BrowserRouter>
          </NotistackProvider>
        </StyledEngineProvider>
      </Provider>
    </RecoilRoot>
  );
}

export default App;
