import React, { lazy } from "react";
import { ThemeProvider } from "@mui/material/styles";

// project imports
import AuthGuard from "../features/auth/AuthGuard";
import Loadable from "../ui-component/Loadable";
import MainLayout from "../features/layout/MainLayout";
import theme from "../theme/main";
import store from "../store";

const JobSummaryView = Loadable(lazy(() => import("../views/JobSummaryView")));
const JobListView = Loadable(lazy(() => import("../views/JobListView")));
const OverviewEditorJsonView = Loadable(lazy(() => import("../views/OverviewEditorJsonView")));
const ReleaseCreateView = Loadable(lazy(() => import("../views/ReleaseCreateView")));
const ReleaseSummaryView = Loadable(lazy(() => import("../views/ReleaseSummaryView")));

const state = store.getState();

const MainRoutes = {
  path: "/",
  element: (
    <ThemeProvider theme={theme(state.theme)}>
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    </ThemeProvider>
  ),
  children: [
    {
      path: "/jobs",
      element: <JobListView />,
    },
    {
      path: "/jobs/:jobId",
      element: <JobSummaryView />,
    },
    {
      path: "/jobs/:jobId/releases",
      element: <ReleaseCreateView />,
    },
    {
      path: "/jobs/:jobId/releases/:releaseId",
      element: <ReleaseSummaryView />,
    },
    {
      path: "/jobs/:jobId/releases/:releaseId/overviews/:overviewId/json",
      element: <OverviewEditorJsonView />,
    },
  ],
};

export default MainRoutes;
