import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from "../../api/client";

const initialState = {
  imageUrl: null,
  jsonUrl: null,
  modified: false,
  overviewId: null,
  status: "idle",
  statusMessage: null,
};

export const fetchOverview = createAsyncThunk("overview/fetchOverview", async (data) => {
  const { overviewId } = data;
  const response = await client.get(`${process.env.REACT_APP_CDS}/editor/v1/overviews/${overviewId}.json`);

  return response.data;
});

export const fetchOverviewJson = createAsyncThunk("overview/fetchOverviewJson", async (data) => {
  const { jsonUrl } = data;
  const response = await client.get(jsonUrl);

  return response.data;
});

export const createOverview = createAsyncThunk("overview/createOverview", async (data) => {
  const response = await client.post(`${process.env.REACT_APP_CDS}/editor/v1/overviews.json`, {
    image_url: data.imageUrl,
    json_url: data.jsonUrl,
  });

  return response.data;
});

export const updateOverview = createAsyncThunk("overview/updateOverview", async (data) => {
  const { overviewId } = data;
  const response = await client.put(`${process.env.REACT_APP_CDS}/editor/v1/overviews/${overviewId}.json`, {
    image_url: data.imageUrl,
    json_url: data.jsonUrl,
  });

  return response.data;
});

export const uploadOverviewJson = createAsyncThunk("overview/uploadOverviewJson", async (data) => {
  const { jobNumber, json } = data;

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: JSON.stringify(json),
    redirect: "follow",
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY}/jobs/${jobNumber}/upload/overview.json`,
    requestOptions,
  );

  if (response.ok) return await response.json();
  throw new Error(response.statusText);
});

/**
 * Download overview json.
 */
export const downloadOverviewJson = createAsyncThunk("overview/downloadOverviewJson", (data) => {
  const { jobNumber, json } = data;

  const file = new Blob([JSON.stringify(json, null, 2)], {
    type: "application/json",
  });
  const link = document.createElement("a");
  link.download = `overview${jobNumber ?? ""}.json`;
  link.href = URL.createObjectURL(file);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
});

export const uploadOverviewRailImage = createAsyncThunk("overview/uploadOverviewRailImage", async (data) => {
  const { jobNumber, imageData } = data;
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "image/png");

  const requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: imageData,
    redirect: "follow",
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY}/jobs/${jobNumber ?? "shared"}/upload/rails.png`,
    requestOptions,
  );

  if (response.ok) return await response.json();
  throw new Error(response.statusText);
});

/**
 * Download overview rail image from Amazon S3.
 * Note that specifying download name only works for same origin URLs.
 * Local development won't rename; however, should work once Route 53 DNS records are established.
 */
export const downloadOverviewRailImage = createAsyncThunk("overview/downloadOverviewRailImage", (data) => {
  const { jobNumber, imageUrl } = data;

  let link = document.createElement("a");
  link.download = `rails${jobNumber ?? ""}.png`;
  link.href = imageUrl;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
});

const overviewSlice = createSlice({
  name: "overview",
  initialState,
  reducers: {
    overviewReset(state, action) {
      state.imageUrl = null;
      state.jsonUrl = null;
      state.modified = false;
      state.overviewId = null;
      state.status = "idle";
      state.statusMessage = null;
    },
    setOverviewId(state, action) {
      state.overviewId = parseInt(action.payload.overviewId);
    },
    setOverviewImageUrl(state, action) {
      state.imageUrl = action.payload.imageUrl;
    },
    setOverviewJsonUrl(state, action) {
      state.jsonUrl = action.payload.jsonUrl;
    },
    setOverviewModified(state, action) {
      const { modified } = action.payload;
      state.modified = modified;
    },
  },
  extraReducers(builder) {
    builder
      // fetch
      .addCase(fetchOverview.pending, (state, action) => {
        state.status = "loading";
        state.statusMessage = "Loading overview...";
      })
      .addCase(fetchOverview.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.imageUrl = action.payload.image_url;
        state.jsonUrl = action.payload.json_url;
      })
      .addCase(fetchOverview.rejected, (state, action) => {
        state.status = "failed";
      })
      // fetchOverviewJson
      .addCase(fetchOverviewJson.pending, (state, action) => {
        state.status = "loading";
        state.statusMessage = "Loading overview json...";
      })
      .addCase(fetchOverviewJson.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(fetchOverviewJson.rejected, (state, action) => {
        state.status = "failed";
      })
      // create
      .addCase(createOverview.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(createOverview.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.statusMessage = "Creating overview...";
        state.overviewId = action.payload.id;
      })
      .addCase(createOverview.rejected, (state, action) => {
        state.status = "failed";
      })
      // update
      .addCase(updateOverview.pending, (state, action) => {
        state.status = "loading";
        state.statusMessage = "Updating overview...";
      })
      .addCase(updateOverview.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(updateOverview.rejected, (state, action) => {
        state.status = "failed";
      })
      // Upload JSON
      .addCase(uploadOverviewJson.pending, (state, action) => {
        state.status = "loading";
        state.statusMessage = "Uploading overview...";
      })
      .addCase(uploadOverviewJson.fulfilled, (state, action) => {
        state.modified = false;
        state.status = "succeeded";
      })
      .addCase(uploadOverviewJson.rejected, (state, action) => {
        state.status = "failed";
      })
      // Upload rail image
      .addCase(uploadOverviewRailImage.pending, (state, action) => {
        state.status = "loading";
        state.statusMessage = "Uploading overview image...";
      })
      .addCase(uploadOverviewRailImage.fulfilled, (state, action) => {
        state.modified = true;
        state.status = "succeeded";
      })
      .addCase(uploadOverviewRailImage.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export const { overviewReset, setOverviewId, setOverviewImageUrl, setOverviewJsonUrl, setOverviewModified } =
  overviewSlice.actions;

export default overviewSlice.reducer;
