/**
 * Highlight mode
 */
export const HighlightMode = {
  DUPLICATES: "DUPLICATES",
  MACHINES: "MACHINES",
  OBJECTS: "OBJECTS",
  UNDEFINED: "UNDEFINED",
  NONE: "NONE",
};
