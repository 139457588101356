import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { client } from "../../api/client";
import { versionComparer } from "../../utils/sort";
import { Environment } from "../../constants/Environment";

const initialState = {
  releases: [],
  status: "idle",
  statusMessage: null,
};

export const fetchReleasesForJob = createAsyncThunk("releases/fetchReleasesForJob", async (data) => {
  const { jobId } = data;
  const response = await client.get(`${process.env.REACT_APP_CDS}/editor/v1/jobs/${jobId}/job_assets.json`);

  return response.data;
});

export const createRelease = createAsyncThunk("releases/create", async (data) => {
  const response = await client.post(`${process.env.REACT_APP_CDS}/editor/v1/jobs/${data.jobId}/job_assets.json`, {
    version: data.version,
    notes: data.notes,
    jobId: data.jobId,
    environment: Environment.NONE,
  });

  return response.data;
});

export const updateRelease = createAsyncThunk("releases/update", async (data) => {
  const response = await client.put(
    `${process.env.REACT_APP_CDS}/editor/v1/jobs/${data.jobId}/job_assets/${data.id}.json`,
    {
      notes: data.notes,
      environment: data.environment,
    },
  );

  return response.data;
});

export const deleteRelease = createAsyncThunk("releases/delete", async (data) => {
  const response = await client.delete(
    `${process.env.REACT_APP_CDS}/editor/v1/jobs/${data.jobId}/job_assets/${data.id}.json`,
    {},
  );

  return response.data;
});

const releasesSlice = createSlice({
  name: "releases",
  initialState,
  reducers: {
    setReleaseEnvironment(state, action) {
      const { releaseId, environment } = action.payload;
      const release = state.releases.find((release) => release.id === parseInt(releaseId));
      if (release) {
        release.environment = environment;
      }
    },
  },
  extraReducers(builder) {
    builder
      // Create release
      .addCase(createRelease.pending, (state, action) => {
        state.status = "loading";
        state.statusMessage = "Creating release...";
      })
      .addCase(createRelease.fulfilled, (state, action) => {
        state.status = "succeeded";
        const myRelease = {
          createdAt: action.payload.created_at,
          environment: action.payload.environment,
          id: action.payload.id,
          jobId: action.payload.job_id,
          notes: action.payload.notes,
          overviewId: action.payload.overview_id,
          updatedAt: action.payload.updated_at,
          version: action.payload.version,
        };
        state.releases = state.releases.concat(myRelease);
      })
      .addCase(createRelease.rejected, (state, action) => {
        state.status = "failed";
      })
      // Fetch releases for job
      .addCase(fetchReleasesForJob.pending, (state, action) => {
        state.status = "loading";
        state.statusMessage = "Loading releases for job...";
      })
      .addCase(fetchReleasesForJob.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.releases = action.payload.map((release) => ({
          createdAt: release.created_at,
          environment: release.environment,
          id: release.id,
          jobId: release.job_id,
          notes: release.notes,
          overviewId: release.overview_id,
          updatedAt: release.updated_at,
          version: release.version,
        }));
      })
      .addCase(fetchReleasesForJob.rejected, (state, action) => {
        state.status = "failed";
      })
      // Update release
      .addCase(updateRelease.pending, (state, action) => {
        state.status = "loading";
        state.statusMessage = "Updating release...";
      })
      .addCase(updateRelease.fulfilled, (state, action) => {
        state.status = "succeeded";
        const release = state.releases.find((release) => release.id === action.payload.id);
        release.createdAt = action.payload.created_at;
        release.environment = action.payload.environment;
        release.jobId = action.payload.job_id;
        release.notes = action.payload.notes;
        release.overviewId = action.payload.overview_id;
        release.updatedAt = action.payload.updated_at;
        release.version = action.payload.version;
      })
      .addCase(updateRelease.rejected, (state, action) => {
        state.status = "failed";
      })
      // Delete release
      .addCase(deleteRelease.pending, (state, action) => {
        state.status = "loading";
        state.statusMessage = "Deleting release...";
      })
      .addCase(deleteRelease.fulfilled, (state, action) => {
        state.status = "succeeded";
        const idx = state.releases.findIndex((release) => release.id === action.meta.arg.id);
        state.releases.splice(idx, 1);
      })
      .addCase(deleteRelease.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export const { setReleaseEnvironment } = releasesSlice.actions;

export default releasesSlice.reducer;

export const latestReleaseForJob = (state) => state.releases.releases?.slice().sort(versionComparer).reverse()[0];

export const releasesForJob = (state) => state.releases.releases?.slice().sort(versionComparer);

export const selectReleaseById = (state, releaseId) =>
  state.releases.releases?.find((release) => release.id === parseInt(releaseId));
