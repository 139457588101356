import InfoIcon from "@mui/icons-material/Info";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FormatListBulletedTwoToneIcon from "@mui/icons-material/FormatListBulletedTwoTone";
import LocalOfferIcon from "@mui/icons-material/LocalOfferTwoTone";
import LocationSearchingIcon from "@mui/icons-material/LocationSearching";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { releasesForJob } from "../features/releases/releasesSlice";
import { selectJobById } from "../features/jobs/jobsSlice";

export const useMenu = () => {
  const params = useParams();
  const releases = useSelector(releasesForJob);
  const job = useSelector((state) => selectJobById(state, params.jobId));

  const jobs = {
    id: "jobs",
    title: "Jobs",
    type: "group",
    children: [
      {
        id: "default",
        title: "Job List",
        type: "item",
        url: "/jobs",
        icon: FormatListBulletedTwoToneIcon,
        breadcrumbs: false,
      },
    ],
  };

  const currentJob = {
    id: "currentJob",
    title: `Current Job: ${job?.number}`,
    type: "group",
    children: [
      {
        id: "summary",
        title: "Summary",
        type: "item",
        url: `/jobs/${job?.id}`,
        icon: InfoIcon,
        breadcrumbs: false,
      },
    ],
  };

  releases
    .slice()
    .reverse()
    .forEach((release) => {
      currentJob.children.push({
        id: `version${release.version}`,
        title: `Version ${release.version}`,
        type: "item",
        url: `/jobs/${job?.id}/releases/${release.id}`,
        icon: LocalOfferIcon,
        breadcrumbs: false,
      });
    });

  currentJob.children.push({
    id: "addRelease",
    title: "Add Release",
    type: "item",
    url: `/jobs/${job?.id}/releases`,
    icon: AddCircleIcon,
    breadcrumbs: false,
  });

  const editor = {
    id: "editor",
    title: "Editor",
    type: "group",
    children: [
      {
        id: "overview",
        title: "Overview",
        type: "item",
        url: "/editor/overview",
        icon: LocationSearchingIcon,
        breadcrumbs: false,
      },
    ],
  };

  return job?.id
    ? {
        items: [jobs, currentJob, editor],
      }
    : {
        items: [jobs, editor],
      };
};
