import React, { lazy } from "react";
import { ThemeProvider } from "@mui/material/styles";

// project imports
import AuthGuard from "../features/auth/AuthGuard";
import Loadable from "../ui-component/Loadable";
import OverviewLayout from "../features/layout/OverviewLayout";
import theme from "../theme/overview";

const OverviewEditorView = Loadable(lazy(() => import("../views/OverviewEditorView")));

const OverviewEditorRoutes = {
  path: "/",
  element: (
    <ThemeProvider theme={theme}>
      <AuthGuard>
        <OverviewLayout />
      </AuthGuard>
    </ThemeProvider>
  ),
  children: [
    {
      path: "/editor/overview",
      element: <OverviewEditorView />,
    },
    {
      path: "/jobs/:jobId/releases/:releaseId/overviews/:overviewId",
      element: <OverviewEditorView />,
    },
  ],
};

export default OverviewEditorRoutes;
