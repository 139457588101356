import React, { lazy } from "react";
import Loadable from "../ui-component/Loadable";
import { ThemeProvider } from "@mui/material/styles";
import { useRoutes } from "react-router-dom";

// routes
import GuestGuard from "../features/auth/GuestGuard";
import FullPageRoutes from "./FullPageRoutes";
import LoginRoutes from "./LoginRoutes";
import MainRoutes from "./MainRoutes";
import OverviewEditorRoutes from "./OverviewEditorRoutes";
import theme from "../theme/main";
import store from "../store";

const LoginView = Loadable(lazy(() => import("../views/LoginView")));
const state = store.getState();

export default function ThemeRoutes() {
  return useRoutes([
    {
      path: "/",
      element: (
        <ThemeProvider theme={theme(state.theme)}>
          <GuestGuard>
            <LoginView />
          </GuestGuard>
        </ThemeProvider>
      ),
    },
    FullPageRoutes,
    LoginRoutes,
    MainRoutes,
    OverviewEditorRoutes,
  ]);
}
