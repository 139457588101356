import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./features/auth/authSlice";
import jobsSlice from "./features/jobs/jobsSlice";
import librarySlice from "./features/library/librarySlice";
import notistackSlice from "./features/notistack/notistackSlice";
import overviewSlice from "./features/overview/overviewSlice";
import releasesSlice from "./features/releases/releasesSlice";
import themeSlice from "./features/theme/themeSlice";
import toolsSlice from "./features/tools/toolsSlice";

const store = configureStore({
  reducer: {
    auth: authSlice,
    jobs: jobsSlice,
    library: librarySlice,
    notistack: notistackSlice,
    overview: overviewSlice,
    releases: releasesSlice,
    theme: themeSlice,
    tools: toolsSlice,
  },
});

export default store;
