/**
 * Sort compare function for objects with numerical `number` property.
 */
export const numberComparer = (a, b) => {
  const n1 = parseInt(a?.number ?? 0);
  const n2 = parseInt(b?.number ?? 0);

  return n1 - n2;
};

/**
 * Sort compare function for objects with numerical `version` property.
 */
export const versionComparer = (a, b) => {
  const n1 = parseInt(a?.version ?? 0);
  const n2 = parseInt(b?.version ?? 0);

  return n1 - n2;
};
