import { createSlice } from "@reduxjs/toolkit";
import { HighlightMode } from "../../constants/HighlightMode";
import { ViewMode } from "../../constants/ViewMode";

const toolsSlice = createSlice({
  name: "tools",
  initialState: {
    findSnackbarOpen: false,
    gridSize: 10,
    gridSizeDialogOpen: false,
    gridVisible: false,
    highlightMode: HighlightMode.NONE,
    highlightSnackbarOpen: false,
    labelFontSize: 10,
    labelFontSizeDialogOpen: false,
    labelsVisible: false,
    lockMachines: false,
    lockSafetyZones: false,
    moveEnabled: true,
    rotateEnabled: true,
    scaleEnabled: true,
    snapToGrid: false,
    viewMode: ViewMode.SELECT,
  },
  reducers: {
    setFindSnackbarOpen: (state, action) => {
      state.findSnackbarOpen = action.payload;
      state.highlightSnackbarOpen = false;
    },
    setGridSize: (state, action) => {
      state.gridSize = action.payload;
    },
    setGridSizeDialogOpen: (state, action) => {
      state.gridSizeDialogOpen = action.payload;
      state.labelFontSizeDialogOpen = false;
    },
    setGridVisible: (state, action) => {
      state.gridVisible = action.payload;
    },
    setHighlightMode: (state, action) => {
      state.highlightMode = action.payload;
      if (state.highlightMode !== HighlightMode.OBJECTS) {
        state.highlightSnackbarOpen = false;
      }
    },
    setHighlightSnackbarOpen: (state, action) => {
      state.highlightSnackbarOpen = action.payload;
      state.findSnackbarOpen = false;
    },
    setLabelFontSize: (state, action) => {
      state.labelFontSize = action.payload;
    },
    setLabelFontSizeDialogOpen: (state, action) => {
      state.labelFontSizeDialogOpen = action.payload;
      state.gridSizeDialogOpen = false;
    },
    setLabelsVisible: (state, action) => {
      state.labelsVisible = action.payload;
    },
    setLockMachines: (state, action) => {
      state.lockMachines = action.payload;
    },
    setLockSafetyZones: (state, action) => {
      state.lockSafetyZones = action.payload;
    },
    setMoveEnabled: (state, action) => {
      state.moveEnabled = action.payload;
    },
    setRotateEnabled: (state, action) => {
      state.rotateEnabled = action.payload;
    },
    setScaleEnabled: (state, action) => {
      state.scaleEnabled = action.payload;
    },
    setSnapToGrid: (state, action) => {
      state.snapToGrid = action.payload;
    },
    setViewMode: (state, action) => {
      state.viewMode = action.payload;
    },
  },
});

export const {
  setFindSnackbarOpen,
  setGridSize,
  setGridSizeDialogOpen,
  setGridVisible,
  setHighlightMode,
  setHighlightSnackbarOpen,
  setLabelFontSize,
  setLabelFontSizeDialogOpen,
  setLabelsVisible,
  setLockMachines,
  setLockSafetyZones,
  setMoveEnabled,
  setRotateEnabled,
  setScaleEnabled,
  setSnapToGrid,
  setViewMode,
} = toolsSlice.actions;

export default toolsSlice.reducer;

export const findSnackbarOpenSelector = (state) => state.tools.findSnackbarOpen;
export const gridSizeSelector = (state) => state.tools.gridSize;
export const gridSizeDialogOpenSelector = (state) => state.tools.gridSizeDialogOpen;
export const gridVisibleSelector = (state) => state.tools.gridVisible;
export const highlightModeSelector = (state) => state.tools.highlightMode;
export const highlightSnackbarOpenSelector = (state) => state.tools.highlightSnackbarOpen;
export const labelFontSizeSelector = (state) => state.tools.labelFontSize;
export const labelFontSizeDialogOpenSelector = (state) => state.tools.labelFontSizeDialogOpen;
export const labelsVisibleSelector = (state) => state.tools.labelsVisible;
export const lockMachinesSelector = (state) => state.tools.lockMachines;
export const lockSafetyZonesSelector = (state) => state.tools.lockSafetyZones;
export const moveEnabledSelector = (state) => state.tools.moveEnabled;
export const rotateEnabledSelector = (state) => state.tools.rotateEnabled;
export const scaleEnabledSelector = (state) => state.tools.scaleEnabled;
export const snapToGridSelector = (state) => state.tools.snapToGrid;
export const viewModeSelector = (state) => state.tools.viewMode;
