import React, { lazy } from "react";
import { ThemeProvider } from "@mui/material/styles";

// project imports
import AuthGuard from "../features/auth/AuthGuard";
import Loadable from "../ui-component/Loadable";
import theme from "../theme/main";
import store from "../store";
import FullPageLayout from "../features/layout/FullPageLayout";

const OverviewIoLabelsView = Loadable(lazy(() => import("../views/OverviewIoLabelsView")));

const state = store.getState();

const MainRoutes = {
  path: "/",
  element: (
    <ThemeProvider theme={theme(state.theme)}>
      <AuthGuard>
        <FullPageLayout />
      </AuthGuard>
    </ThemeProvider>
  ),
  children: [
    {
      path: "/jobs/:jobId/releases/:releaseId/overviews/:overviewId/io-labels",
      element: <OverviewIoLabelsView />,
    },
  ],
};

export default MainRoutes;
