import PropTypes from "prop-types";
import { createContext, useEffect } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

// third-party
import jwtDecode from "jwt-decode";

// project imports
import Loader from "../ui-component/Loader";
import { authIsInitialized, loginCDS, logoutCDS, setLogin, setLogout } from "../features/auth/authSlice";
import { getCurrentUser } from "../services/UserService";
import { notifyError } from "../features/notistack/notistackSlice";
import store from "../store";

const verifyToken = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }
  const decoded = jwtDecode(serviceToken);
  /**
   * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
   */
  return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken) => {
  if (serviceToken) {
    localStorage.setItem("serviceToken", serviceToken);
  } else {
    localStorage.removeItem("serviceToken");
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
  const isInitialized = useSelector(authIsInitialized);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = window.localStorage.getItem("serviceToken");

        if (serviceToken && verifyToken(serviceToken)) {
          setSession(serviceToken);
          dispatch(setLogin());
          await getCurrentUser();
        } else {
          setSession(null);
          dispatch(setLogout());
        }
      } catch (err) {
        console.error(err);
        dispatch(setLogout());
      }
    };

    const token = searchParams.get("token");
    if (token) {
      setSession(`Bearer ${token}`);
      dispatch(setLogin());
    } else {
      init();
    }
  }, []);

  const login = async (email, password) => {
    const response = await dispatch(loginCDS({ email: email, password: password }));

    try {
      unwrapResult(response);
      const token = response?.payload?.headers?.get("authorization");
      setSession(token);
      dispatch(setLogin());
      getCurrentUser();
    } catch (error) {
      store.dispatch(notifyError(`Login failed: ${response?.error?.message}`));
      console.error(error);
    }
  };

  const logout = async () => {
    await dispatch(logoutCDS());
    setSession(null);
    dispatch(setLogout());
  };

  if (isInitialized !== undefined && !isInitialized) {
    return <Loader />;
  }

  return <JWTContext.Provider value={{ login, logout }}>{children}</JWTContext.Provider>;
};

JWTProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default JWTContext;
