import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  borderRadius: 12,
  drawerWidth: 260,
  fontFamily: `'Roboto', sans-serif`,
  gridSpacing: 3,
  isOpen: [],
  navType: "dark",
  opened: true,
};

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setMenuId(state, action) {
      const { id } = action.payload;
      state.isOpen = [id];
    },
    setMenuOpen(state, action) {
      const { opened } = action.payload;
      state.opened = opened;
    },
  },
});

export const { setMenuId, setMenuOpen } = themeSlice.actions;

export default themeSlice.reducer;
