import { createSlice } from "@reduxjs/toolkit";

const librarySlice = createSlice({
  name: "library",
  initialState: {
    panels: {},
  },
  reducers: {
    resetPanels: (state, action) => {
      state.panels = {};
    },
    setPanels: (state, action) => {
      state.panels = action.payload;
    },
  },
});

export const { resetPanels, setPanels } = librarySlice.actions;

export default librarySlice.reducer;

export const panelsSelector = (state) => state.library.panels;
